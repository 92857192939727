import { A, cache, createAsync, useNavigate } from "@solidjs/router";
import { Accessor, createSignal, onMount, Show, Suspense } from "solid-js";
import { useProcurement } from "~/context/procurement";
import { config } from "~/data/config";
import { getProcurementProformaInvoicesRouteData } from "~/server/data/procurement_proforma_invoices_route_data";
import { ProcurementProformaInvoicesResponse } from "~/server/types/order";
import { Divider } from "~/widgets/divider";
import { EmptyState } from "~/widgets/empty_state";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";
import { formatDateShort, formatDateToDDMMMMYYYY } from "~/utils/date";

const getProcurementProformaInvoicesRouteData$C = cache(
  getProcurementProformaInvoicesRouteData,
  "procurement_proforma_invoices"
);

export default function ProformaInvoice() {
  const routeData: Accessor<ProcurementProformaInvoicesResponse | undefined> =
    createAsync<ProcurementProformaInvoicesResponse>(() => {
      return getProcurementProformaInvoicesRouteData$C();
    });
  const [activeRow, setActiveRow] = createSignal<string>("");
  const { updatePIInfo, addToCart, clearCart } = useProcurement();
  const navigator = useNavigate();

  return (
    <Suspense
      fallback={
        <div class="flex w-full items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="w-full">
        <div class="px-5 py-4 text-h5 text-black">Proforma invoice</div>
        <Divider />
        {PITable()}
      </div>
    </Suspense>
  );

  function PITable() {
    const navigate = useNavigate();

    return (
      <div class="h-[90vh] overflow-auto p-4 pb-10">
        <div class="shadow rounded-lg border border-baseTertiaryMedium bg-white">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="sticky -top-5 rounded-full  bg-gray-50">
              <tr>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  ID
                </th>
                <th class="rounded-tl-lg border-r  px-3 py-2 text-left text-smallBold text-textDark">
                  DATE
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r px-3  py-2 text-left text-smallBold text-textDark">
                  BRANDS
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y divide-gray-200  rounded-full bg-white ">
              {routeData()?.data.map((PI) => (
                <tr
                  class={` text-medium text-textDark  last:rounded-b-lg hover:cursor-pointer   hover:bg-baseTertiaryMedium`}
                  onMouseEnter={() => {
                    setActiveRow(PI.proformaInvoiceId);
                  }}
                  onMouseLeave={() => {
                    setActiveRow("");
                  }}
                  onClick={() => {
                    navigate(`/proforma-invoice/${encodeURIComponent(PI.id)}`);
                  }}
                >
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold last:rounded-bl-lg">
                    <div>{PI.proformaInvoiceId}</div>
                  </td>
                  <td class="whitespace-nowrap border-r p-3">
                    {formatDateToDDMMMMYYYY(new Date(PI.date))}
                  </td>
                  <td class="whitespace-nowrap border-r p-3">{PI.dueAmount}</td>

                  <td class="relative whitespace-nowrap border-r p-3">
                    <div class="flex items-center justify-center">
                      <div class="grow">
                        {PI.products.length > 4
                          ? PI.products
                              .slice(0, 4)
                              .map((item) => item.productName)
                              .join(", ")
                              .toString() +
                            " +" +
                            (PI.products.length - 4) +
                            " more"
                          : PI.products
                              .map((item) => item.productName)
                              .join(", ")}
                      </div>
                      <Show when={activeRow() === PI.proformaInvoiceId}>
                        <div class="absolute  right-0 flex items-center justify-center pr-3">
                          <A
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            href={
                              config.receiptUrl + "/proforma-invoice/" + PI.id
                            }
                            target="_blank"
                          >
                            <div class="mx-2 flex h-7 w-7 items-center justify-center rounded-[8px] border border-baseTertiaryDark bg-white">
                              <PhosphorIcon name="file-pdf" fontSize={20} />
                            </div>
                          </A>
                          <div
                            class="  rounded-[8px] bg-black px-3 py-2 text-mediumBold text-white"
                            onClick={(event) => {
                              event.stopPropagation();
                              clearCart();
                              updatePIInfo(PI.id);
                              PI.products.map((product) => {
                                product.denominationDetails.length == 1
                                  ? addToCart({
                                      productId: product.productId,
                                      amount: product.amount,
                                      denomination:
                                        product.denominationDetails[0]
                                          .denomination,
                                      quantity:
                                        product.denominationDetails[0].quantity,
                                      detail: {
                                        logoUrl: "",
                                        brandName: product.productName,
                                      },
                                    })
                                  : product.denominationDetails.map((pdt) => {
                                      addToCart({
                                        productId: product.productId,
                                        amount: product.amount,
                                        denomination: pdt.denomination,
                                        quantity: pdt.quantity,
                                        detail: {
                                          logoUrl: "",
                                          brandName: product.productName,
                                        },
                                      });
                                    });
                              });

                              //update cart with products
                              //add PIID
                              //navigate to checkout
                              navigator("/checkout");
                            }}
                          >
                            ORDER NOW
                          </div>
                        </div>
                      </Show>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function emptyState() {
    return (
      <EmptyState
        primaryText="Gifts cards you purchase will appear here"
        secondaryText="No gift cards purchased yet"
      />
    );
  }
}
